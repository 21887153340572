import { t } from "@lingui/macro";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { DestinationPage } from "~/applications/OfferSearch/Domain/DestinationPage";
import Carousel from "~/components/Carousel/Carousel";
import { Stack } from "~/guidelines/Flex";
import SectionTitle from "~/guidelines/SectionTitle";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import MarketingLogos from "./MarketingLogos";
import TestimonialCard from "./TestimonialCard";

const Wrapper = styled(Stack)`
  width: 100%;
  padding: 72px 0 120px 0;
  gap: ${({ theme }) => theme.spacing(7)};
  swiper-slide {
    max-width: 300px;
  }
  ${mediaQueries.md()} {
    padding: 80px 0;
    gap: 90px;
    text-align: center;
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  padding: ${({ theme }) => theme.spacing(7, 5, 0, 5)};
`;
export function Testimonials({
  destinations,
}: {
  destinations: DestinationPage[] | null;
}) {
  const data = [
    {
      text: t`Lovely stay here! So easy to use and the local breakfast was a treat. Beautiful facilities and a great short stay.`,
      guest: "Jezabell H.",
      hotel: "Limoges, Hôtel Préfecture",
      destination: destinations?.find((d) => d.seoSlug === "limoges"),
      source: "google.com",
      nbStars: 5,
    },
    {
      text: t`Superb decoration, comfortable bedding, very clean, everything is designed to satisfy the customer, very beautiful terrace and large garden.`,
      guest: "Leslie A.",
      hotel: "Orléans, Hôtel Arago",
      destination: destinations?.find((d) => d.seoSlug === "orleans"),
      source: "booking.com",
      nbStars: 5,
    },
    {
      text: t`1st experience in a self-check-in hotel... everything was very simple and intuitive. The hotel is ideally located in Tours. The room space is well optimized, with modern decor and excellent bedding.`,
      guest: "Tristan T.",
      hotel: "Tours, Hôtel Corneille",
      destination: destinations?.find((d) => d.seoSlug === "tours"),
      source: "expedia",
      nbStars: 5,
    },
    {
      text: t`Everything was perfect. The self-check-in was very convenient. The room was spacious, well-designed, and beautifully decorated. The breakfast was plentiful. We highly recommend it and will not hesitate to come back!`,
      guest: "Jessica L.",
      hotel: "Orléans, Hôtel Arago",
      destination: destinations?.find((d) => d.seoSlug === "orleans"),
      source: "google.com",
      nbStars: 5,
    },
    {
      text: t`A tastefully decorated, welcoming and clean place. ++++ comfort, full breakfast. The location is ideal in a pedestrian area a stone's throw from the cathedral (tram stop). Independent access, but team can be reached if necessary, friendly and responsive.`,
      guest: "Isabelle B.C.",
      hotel: "Orléans, Hôtel 7 Dormants",
      destination: destinations?.find((d) => d.seoSlug === "orleans"),
      source: "booking.com",
      nbStars: 5,
    },
    {
      text: t`Good hotel just 1 minute from Orléans Cathedral. Spacious room and excellent bed. We really liked the decoration`,
      guest: "Anne B.",
      hotel: "Orléans, Hôtel Le Cloitre",
      destination: destinations?.find((d) => d.seoSlug === "orleans"),
      source: "google.com",
      nbStars: 5,
    },
    {
      text: t`Practical, impeccably clean, well-located, very responsive, and innovative. Excellent value for money. An innovative concept. Nothing to complain about except to say, "I’ll gladly come back!"`,
      guest: "Anne L.",
      hotel: "Orléans, Hôtel Arago",
      destination: destinations?.find((d) => d.seoSlug === "orleans"),
      source: "google.com",
      nbStars: 5,
    },
    {
      text: t`A very pleasant hotel with a very comfortable bed, ideally located for exploring Arles and its surroundings! The highlight of the hotel: a carefully curated decor combining design and retro elements.`,
      guest: "Amélie M.",
      hotel: "Arles, Hôtel Présent",
      destination: destinations?.find((d) => d.seoSlug === "arles"),
      source: "google.com",
      nbStars: 5,
    },
    {
      text: t`Simply an excellent hotel with great service. Impeccable value for money. We wish the entire hospitality industry would adopt their philosophy!`,
      guest: "Caroline J.",
      hotel: "Arles, Hôtel Présent",
      destination: destinations?.find((d) => d.seoSlug === "arles"),
      source: "google.com",
      nbStars: 5,
    },
    {
      text: t`Prime location in the city center of Limoges, spacious accommodation, bathroom equipped with high-quality toiletries, enjoyable and complete breakfast, fully autonomous check-in and check-out, and responsive customer service.`,
      guest: "Aurélie C. ",
      hotel: "Limoges, Hôtel Préfecture",
      destination: destinations?.find((d) => d.seoSlug === "limoges"),
      source: "booking.com",
      nbStars: 5,
    },
    {
      text: t`The quality of the accommodation with a beautiful dining room (and its garden) accessible at any time. Complimentary coffee and tea. A available fridge. A concept based on trust and respect.`,
      guest: "Chantry V.",
      hotel: "Limoges, Hôtel Gambettes",
      destination: destinations?.find((d) => d.seoSlug === "limoges"),
      source: "booking.com",
      nbStars: 5,
    },
    {
      text: t`Perfect concept! A great location for exploring the city center! The establishment is extremely clean, the room is spotless and quiet, and the breakfast is excellent!`,
      guest: "Yoland D.",
      hotel: "Orléans, Hôtel Fernand",
      destination: destinations?.find((d) => d.seoSlug === "orleans"),
      source: "google.com",
      nbStars: 5,
    },
  ];

  const [offsetValue, setOffsetValue] = useState(16);
  const [slidesPerGroup, setSlidesPerGroup] = useState(1);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const windowWidth = window.innerWidth;
      // Si l'écran est plus grand que 1440px, calculer la marge, sinon utiliser 16px
      setOffsetValue(windowWidth > 1440 ? (windowWidth - 1440) / 2 : 16);
      setSlidesPerGroup(windowWidth > 768 ? 4 : 1);
    }
  }, []);

  return (
    <Wrapper>
      <StyledSectionTitle
        firstPart={t`We're not making it up`}
        secondPart={t`It comes from your own mouth`}
      />
      <Carousel
        items={data}
        centered={false}
        offsetBefore={offsetValue}
        group={slidesPerGroup}
      >
        {(testimonial) => <TestimonialCard {...testimonial} />}
      </Carousel>
      <MarketingLogos />
    </Wrapper>
  );
}
