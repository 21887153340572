import styled from "styled-components";
import { DestinationPage } from "~/applications/OfferSearch/Domain/DestinationPage";
import CapsuleBox from "~/components/Destination/Capsule";
import { Flex, Stack } from "~/guidelines/Flex";
import { body1, body2, body2Bold } from "~/guidelines/Typography";

const Card = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => theme.spacing(8, 6)};
  align-items: flex-start;
  width: 300px;
  height: 361px;
  flex: 1 0 0;
  background: ${({ theme }) => theme.color.background.lightFirst};
  border-radius: ${({ theme }) => theme.borderRadius.xlarge};
  box-shadow:
    0px 3px 5px 0px rgba(104, 85, 39, 0.05),
    0px 9px 16px 0px rgba(104, 85, 39, 0.07);
  text-align: left;
`;
const Text = styled.p`
  ${body1}
  margin: 0;
  color: ${({ theme }) => theme.color.text.lightThird};
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Top = styled(Stack)`
  gap: 4px;
`;

const Guest = styled.div`
  ${body2Bold}
  color: ${({ theme }) => theme.color.text.lightFirst};
`;
const Info = styled.div`
  ${body2}
  color: ${({ theme }) => theme.color.text.lightSecond};
`;

const Destination = styled(Flex)`
  ${body2Bold}
  color: ${({ theme }) => theme.color.text.lightThird};
  gap: ${({ theme }) => theme.spacing(6)};
  align-items: center;
  align-self: stretch;
`;

export default function TestimonialCard({
  text,
  guest,
  hotel,
  source,
  destination,
}: {
  text: string;
  guest: string;
  hotel: string;
  source: string;
  destination: DestinationPage | undefined;
}) {
  return (
    <Card>
      <Top>
        <Guest>{guest}</Guest>
        <Info>{source}</Info>
      </Top>
      <Text>{text}</Text>

      <Destination>
        {destination?.capsule && <CapsuleBox capsule={destination.capsule} />}
        <span>{hotel}</span>
      </Destination>
    </Card>
  );
}
