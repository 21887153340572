import { RefObject } from "react";
import styled from "styled-components";
import { SwiperContainer } from "swiper/element";
import { IconOnlyButton } from "~/guidelines/Button";
import { Flex } from "~/guidelines/Flex";
import { NavigationArrowForward } from "~/guidelines/Icon";
import NavigationArrowBack from "~/guidelines/Icon/NavigationArrowBack";
import mediaQueries from "~/guidelines/Theme/mediaQueries";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  padding: ${({ theme }) => theme.spacing(7, 5, 0)};
  ${mediaQueries.md()} {
    gap: 19px;
  }
`;

const Pagination = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(3)};
  align-items: center;
  ${mediaQueries.md()} {
    padding: ${({ theme }) => theme.spacing(3)};
  }
`;

const Control = styled(Flex)`
  padding: ${({ theme }) => theme.spacing(4)};
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(3)};
  align-items: center;
`;

const Circle = styled.span`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background: ${({ theme }) => theme.color.background.lightThird};
`;

const CircleFilled = styled.span`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.color.background.darkTertiary};
`;

const CircleIconOnlyButton = styled(IconOnlyButton)`
  border-color: ${({ theme }) => theme.color.text.lightFirst};
  color: ${({ theme }) => theme.color.text.lightFirst};
`;

const Navigation = ({
  swiperRef,
  activeIndex,
  length,
  ...props
}: {
  swiperRef: RefObject<SwiperContainer>;
  activeIndex: number;
  length: number;
  className?: string;
}) => {
  const handlePrev = () => {
    swiperRef.current?.swiper.slidePrev();
  };
  const handleNext = () => {
    swiperRef.current?.swiper.slideNext();
  };

  return (
    <Container className={props.className}>
      <Control>
        <CircleIconOnlyButton
          kind="tertiary"
          isDisabled={activeIndex == 0}
          icon={<NavigationArrowBack />}
          onPress={handlePrev}
        ></CircleIconOnlyButton>
      </Control>
      <Pagination>
        {Array.from({ length }).map((_, index) =>
          index === activeIndex ? (
            <CircleFilled key={index} />
          ) : (
            <Circle key={index} />
          ),
        )}
      </Pagination>
      <Control>
        <IconOnlyButton
          kind="tertiary"
          isDisabled={activeIndex == length - 1}
          icon={<NavigationArrowForward />}
          onPress={handleNext}
        ></IconOnlyButton>
      </Control>
    </Container>
  );
};

export default Navigation;
