import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Swiper from "swiper";
import { SwiperContainer, register } from "swiper/element";
import Navigation from "./Navigation";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const Carousel = <T,>({
  items,
  children,
  centered = true,
  offsetBefore = 0,
  group = 1,
  ...props
}: {
  items: T[];
  children: (item: T, index: number) => React.ReactNode;
  className?: string;
  centered?: boolean;
  offsetBefore?: number;
  group?: number;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<SwiperContainer>(null);

  useEffect(() => {
    register();

    if (swiperRef.current === null) {
      return;
    }

    const swiperParams = {
      breakpoints: {
        768: {
          spaceBetween: 40,
        },
      },
    };
    Object.assign(swiperRef.current, swiperParams);
    swiperRef.current.initialize();
    swiperRef.current.swiper.on("activeIndexChange", (swiper: Swiper) => {
      setActiveIndex(swiper.activeIndex);
    });
  }, [swiperRef]);

  return (
    <Wrapper className={props.className}>
      <swiper-container
        init={false}
        ref={swiperRef}
        slides-per-view={"auto"}
        slides-per-group={group}
        centered-slides={centered}
        space-between={16}
        slides-offset-before={offsetBefore}
      >
        {items.map((item, index) => (
          <swiper-slide key={index}>{children(item, index)}</swiper-slide>
        ))}
      </swiper-container>
      <Navigation
        swiperRef={swiperRef}
        activeIndex={Math.ceil(activeIndex / group)}
        length={Math.ceil(items.length / group)}
      />
    </Wrapper>
  );
};

export default Carousel;
