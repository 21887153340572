import styled from "styled-components";
import { heading1, heading5 } from "./Typography";

const Container = styled.h2`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FirstPart = styled.span`
  ${heading5}
  display: block;
`;

const SecondPart = styled.span`
  ${heading1}
  display: block;
`;

const SectionTitle = ({
  firstPart,
  secondPart,
  ...props
}: {
  firstPart: string;
  secondPart: string;
  className?: string;
}) => (
  <Container className={props.className}>
    <FirstPart>{firstPart}</FirstPart>
    <SecondPart>{secondPart}</SecondPart>
  </Container>
);

export default SectionTitle;
