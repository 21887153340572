import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const NavigationArrowBack = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M11.3344 1.98914C11.0078 1.66247 10.4811 1.66247 10.1544 1.98914L4.61443 7.52914C4.35443 7.78914 4.35443 8.20914 4.61443 8.46914L10.1544 14.0091C10.4811 14.3358 11.0078 14.3358 11.3344 14.0091C11.6611 13.6825 11.6611 13.1558 11.3344 12.8291L6.50777 7.99581L11.3411 3.16247C11.6611 2.84247 11.6611 2.30914 11.3344 1.98914Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default NavigationArrowBack;
