import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const NavigationArrowForward = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M7.22618 21.015C7.71618 21.505 8.50618 21.505 8.99618 21.015L17.3062 12.705C17.6962 12.315 17.6962 11.685 17.3062 11.295L8.99618 2.98499C8.50618 2.49499 7.71618 2.49499 7.22618 2.98499C6.73618 3.47499 6.73618 4.26499 7.22618 4.75499L14.4662 12.005L7.21618 19.255C6.73618 19.735 6.73618 20.535 7.22618 21.015Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default NavigationArrowForward;
