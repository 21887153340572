import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const PinPoint = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.22803 9.26501C5.22803 5.39501 8.35803 2.26501 12.228 2.26501C16.098 2.26501 19.228 5.39501 19.228 9.26501C19.228 13.435 14.808 19.185 12.998 21.375C12.598 21.855 11.868 21.855 11.468 21.375C9.64803 19.185 5.22803 13.435 5.22803 9.26501ZM9.72803 9.26501C9.72803 10.645 10.848 11.765 12.228 11.765C13.608 11.765 14.728 10.645 14.728 9.26501C14.728 7.88501 13.608 6.76501 12.228 6.76501C10.848 6.76501 9.72803 7.88501 9.72803 9.26501Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default PinPoint;
