import NextImage from "next/image";
import styled from "styled-components";
import { Flex, Stack } from "~/guidelines/Flex";
import { Star } from "~/guidelines/Icon";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body1 } from "~/guidelines/Typography";
import AirbnbLogo from "../_marketing/Airbnb_Logo.svg";
import BookingComLogo from "../_marketing/Booking.com_logo.svg";
import GoogleLogo from "../_marketing/Google_2015_logo.svg";
import SuperHost from "../_marketing/superhost.png";

const Marketing = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(5)};
  ${body1};
  align-items: center;

  ${mediaQueries.md()} {
    flex-direction: row;
    justify-content: center;
  }
`;

const LogoReview = styled(Flex)`
  gap: ${({ theme }) => theme.spacing(3)};
`;

const Logo = styled(NextImage)`
  max-height: 24px;
`;

const Text = styled.span`
  ${body1};
  font-size: 10px;
  color: ${({ theme }) => theme.color.custom.green};
  line-height: 110%;
  text-align: center;
  width: 80px;
`;

export default function MarketingLogos() {
  return (
    <Marketing>
      <LogoReview>
        <Logo src={GoogleLogo} alt="Google" height="24" />{" "}
        <span>
          4,5/5 <Star />
        </span>
      </LogoReview>
      <LogoReview>
        <Logo src={BookingComLogo} alt="Booking.com" height="24" />
        <Text>Traveler Review Awards 2024</Text>
      </LogoReview>
      <LogoReview>
        <Logo src={AirbnbLogo} alt="Airbnb" height="24" />
        <Logo src={SuperHost} alt="superhost" height="24" />
      </LogoReview>
    </Marketing>
  );
}
